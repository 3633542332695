import React from "react"
import { Link, graphql } from "gatsby"

import BlogLayout from "../components/blog-layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.blogTitle || data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const daysInMilliseconds = 7 * 24 * 60 * 60 * 1000

  return (
    <BlogLayout location={location} title={siteTitle}>
      <SEO title={siteTitle} />
      {posts.map(({ node }) => {
        const postDate = new Date(node.frontmatter.date)
        const currentDate = new Date()
        const title = node.frontmatter.title || node.fields.slug
        // Skip standalone pages.
        if (node.frontmatter.displayFooterNav === "no") return (<></>);
        return (
          <article key={node.fields.slug} className="my-10">
            <header>
              <h3 className="mb-2">
                <Link to={node.fields.slug} className="no-frills">
                  {title}
                </Link>
              </h3>
              {(currentDate -  postDate) <= daysInMilliseconds && (
                <small className="new-tag mr-2 rounded bg-purple-300 text-xs font-bold"><small>new</small></small>
              )}
              {node.frontmatter.showTimestamp !== "no" && (
                <>
                <small className="text-gray-600 mr-2">{node.frontmatter.date}</small>
                <small className="text-gray-600 mr-2">·</small>
                <small className="text-gray-600 mr-2">{node.frontmatter.tags || "Uncategorized"}</small>
                </>
              )}
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </BlogLayout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            displayFooterNav
            showTimestamp
          }
        }
      }
    }
  }
`
